import { useEnhanceReducer } from 'customHooks/enhanceReducer';
import * as types from 'constants/actionTypes';
import { COURSE_TYPE } from 'constants/courseTypes';
import { EmojiTypes } from 'constants/expressionTypes';

export const initState = {
  courseId: '',
  timeSpanId: '',
  startAt: '',
  startIn: '',
  endAt: '',
  finishedBefore: '',
  organizationId: null,
  remoteZoomInfo: null,
  firestore: null,
  chimeRole:'',
  teacherAttendeeId: '',
  courseType: COURSE_TYPE.Normal,
  isMultiple: false,
  rewardInfo: { fireworks: 0, good: 0, like: 0, trophy: 0 },
  openStartCourseDialog: false,
  isFullVideo:false,
  fullScreenTarget:'',
};

const courseReducer = (state, action) => {
  switch (action.type) {
    case types.SET_COURSE_INFO:
      const { id, timeSpanId, startAt, endAt, startIn, finishedBefore, organizationId, type, users } = action.courseInfo;
      return { 
        ...state, 
        courseId: id, 
        timeSpanId, 
        startAt, 
        endAt, 
        startIn, 
        finishedBefore,
        organizationId, 
        courseType: type,
        isMultiple: (users || []).length > 1
      };
    case types.SET_REMOTE_ZOOM_INFO:
      return { ...state, remoteZoomInfo: action.remoteZoomInfo };
    case types.SET_COURSE_FIRESTORE:
      return { ...state, firestore: action.firestore };
    case types.SET_CHIME_ROLE:
      return { ...state, chimeRole: action.chimeRole };
    case types.SET_REWARD_INFO:
      return { 
        ...state, 
        rewardInfo: {
          ...state.rewardInfo,
          ...action.rewardInfo
        } 
      }
    case types.INCREASE_REWARD:
      const { expressionType } = action;
      const emojiType = EmojiTypes[expressionType];
      const rewardInfo  = {
        ...state.rewardInfo,
        [emojiType]: state.rewardInfo[emojiType] + 1
      }
      return { ...state, rewardInfo };
    case types.SET_CHIME_TEACHER_ATTENDEE_ID:
      const { attendeeId } = action.payload;
      return { ...state, teacherAttendeeId: attendeeId };
    case types.OPEN_START_COURSE_DIALOG:
      return { ...state, openStartCourseDialog: action.openStartCourseDialog };

    case types.SET_IS_FULL_VIDEO:
      return { ...state, isFullVideo: action.isFullVideo };
    case types.SET_ADMIN_FULL_SCREEN_TARGET:
      return { ...state, fullScreenTarget: action.fullScreenTarget };
    default:
      return state;
  }
};

const useCourseReducer = () => useEnhanceReducer(courseReducer, initState);

export default useCourseReducer;
