import React, { useState, useEffect } from 'react';
import {
  RemoteVideo,
  useMeetingManager,
  useRemoteVideoTileState,
} from 'amazon-chime-sdk-component-library-react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import LiveStreamStatusMeter from 'components/LiveStreamStatusMeter';
import styles from './index.module.scss';

export const LiveStreamMasterVideo = React.memo(({ isFull }) => {
  const [, courseDispatch] = useStore(StoreTypes.course);
  const meetingManager = useMeetingManager();
  const meetingId = meetingManager.meetingId;
  const [attendeeId, setAttendeeId] = useState('');
  const [teacherName, setTeacherName] = useState('');

  const { attendeeIdToTileId } = useRemoteVideoTileState();

  useEffect(() => {
      if (!meetingId || attendeeId) return;
      const getTeacherAttendeeId = async () => {
          const response =
              await fetch(`${process.env.REACT_APP_CHIME_API_URL}/getTeacher?meetingId=${meetingId}`);
          const { data } = await response.json();
          if (data) {
              setAttendeeId(data.AttendeeId)
              setTeacherName(data.Name)
              courseDispatch({ 
                type: types.SET_CHIME_TEACHER_ATTENDEE_ID, 
                payload: { attendeeId: data.AttendeeId } 
              })
          }
      }

      getTeacherAttendeeId();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingId, attendeeIdToTileId])


  return attendeeId && attendeeIdToTileId[attendeeId] ?
      <div className={styles.teacherVideo}>
          <RemoteVideo tileId={attendeeIdToTileId[attendeeId]} css={`height: 150px`}/>
          {
              !isFull && <LiveStreamStatusMeter attendeeId={attendeeId} userName={teacherName} />
          }
      </div>
      : null
});

export default LiveStreamMasterVideo;