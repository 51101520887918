import React,{ useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Provider from './context';
import { AppService } from 'components/AppService';
import Bookshelf from 'components/Bookshelf';
import { BookStore } from 'components/BookStore';
import Placeholder from 'components/Placeholder';
import ReaderActivity from 'components/ReaderActivity';
import { Beforeunload } from 'components/Beforeunload';
import { useCourse } from 'customHooks/course';
import { ThemeProvider } from 'styled-components';
import { MeetingProvider } from 'amazon-chime-sdk-component-library-react';
import theme from './theme';


const SubscribeBroadcastedEventService = () => {
  const [_,{ subscribeBroadcastedEvent }] = useCourse();

  useEffect(() => {
    subscribeBroadcastedEvent();
  },[subscribeBroadcastedEvent])

  return null;
}

const HookedBrowserRouter = ({ children }) => (
  <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
    <Route>
      {(routeProps) => (
        <Provider router={routeProps}>
          {children}
        </Provider>
      )}
    </Route>
  </BrowserRouter>
);

const App = () => {
  return (
    <Beforeunload>
      <ThemeProvider theme={theme}>
        <MeetingProvider>
          <HookedBrowserRouter>
            <AppService />
            <SubscribeBroadcastedEventService/>
            <Switch>
            <Route exact path='/bookshelf' component={Bookshelf} />
              <Route exact path='/bookstore' component={BookStore} />
              <Route exact path='/loading' component={Placeholder} />
              <Route exact path='/:bookId' component={ReaderActivity} />
              <Redirect path='*' to='/bookshelf' />
            </Switch>
          </HookedBrowserRouter>
        </MeetingProvider>
      </ThemeProvider>
    </Beforeunload>
  );
};

export default App;

