import React, { useEffect, useState } from 'react';
import Icon from 'components/Icon';
import styled from 'styled-components';
import { useAudioVideo, Severity } from 'amazon-chime-sdk-component-library-react';

const VariantType = {
  Small: 'small',
  Large: 'large'
}

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #33353A;
    border-radius: 0px 0px 8px 8px;
    padding: ${({ variant }) => variant === VariantType.Large ? ` 8px 16px` : `4px`};
    font-size: 14px;
    /* margin-bottom: 8px; */
    
    > .status{
      display: flex;
      align-items: center;
      justify-content: center;
    }
`;

const StyledIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

  & + &{
    margin-left: 8px;
  }
`

const STRENGTH = {
  WEAK: 'Weak',
  MEDIUM: 'Medium',
  STRONG: 'Strong'
};

const getVolumeIcon = (volume = 0) => {
  let strength = STRENGTH.WEAK;
  if(volume > 0) strength = STRENGTH.MEDIUM;
  if(volume > 0.5) strength =  STRENGTH.STRONG;
  return <Icon type="svg" name={`Volume${strength}`} />
}

const LiveStreamStatusMeter = ({ 
  attendeeId,
  userName,
  variant = VariantType.Large
}) => {

  const audioVideo = useAudioVideo();
  const [connectionStatus,setConnectionStatus] = useState(Severity.INFO);
  const [volumeStrength,setVolumeStrength] = useState(0);
  
  useEffect(() => {
    if(!audioVideo || !attendeeId) return;
    const connectionObserver = {
      audioVideoDidStart: () => {
        setConnectionStatus(Severity.SUCCESS)
      },
      audioVideoDidStartConnecting: () => {
        setConnectionStatus(Severity.INFO);
      },
      connectionDidBecomeGood: () => {
        setConnectionStatus(Severity.SUCCESS);
      },
      connectionDidBecomePoor: () => {
        setConnectionStatus(Severity.WARNING);
      },
      connectionDidSuggestStopVideo: () => {
        setConnectionStatus(Severity.ERROR);
      },
      audioVideoDidStop: () => {
        setConnectionStatus(Severity.ERROR);
      },
    };

    audioVideo.addObserver(connectionObserver);

    const volumeHandler = (
      attendeeId,
      volume,
      muted,
      signalStrength
    ) => {
      if (volume !== null) {
        setVolumeStrength(volume);
      }
    }

    audioVideo.realtimeSubscribeToVolumeIndicator(attendeeId, volumeHandler)

    return () => {
      audioVideo.removeObserver(connectionObserver);
      audioVideo.realtimeUnsubscribeFromVolumeIndicator(attendeeId, volumeHandler);
    }
    
  },[attendeeId, audioVideo])

  return <StyledContainer variant={variant}>
    <span>{userName}</span>
    {
      variant === VariantType.Large && (
        <div className="status">
          <StyledIcon>
            {
              getVolumeIcon(volumeStrength)
            }
          </StyledIcon>
          <StyledIcon>
            {
              (connectionStatus === Severity.ERROR || connectionStatus === Severity.WARNING) && (
                <Icon type="svg" name="SignalWeak" />
              )  
            }
            {
              (connectionStatus === Severity.INFO || connectionStatus === Severity.SUCCESS) && (
                <Icon type="svg" name="SignalStrong" />
              )  
            }
          </StyledIcon>
        </div>
      )
    }
  </StyledContainer>
}

export default LiveStreamStatusMeter;