import React, { useState, useEffect } from 'react';
import { RemoteVideo, useRosterState, useRemoteVideoTileState } from 'amazon-chime-sdk-component-library-react';
import { Roles } from 'constants/role';
import { useStore, StoreTypes } from 'context/index';
import LiveStreamStatusMeter from 'components/LiveStreamStatusMeter';
import styles from './index.module.scss';

 const LiveStreamRemoteVideo = React.memo(({ isFull }) => {
  const [attendeeId, setAttendeeId] = useState('');
  const { roster } = useRosterState();
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const [{ name: userName }] = useStore(StoreTypes.user);


  useEffect(() => {
    const student = Object.values(roster).find(item => item.externalUserId.split("$")[1] === Roles.ONECLASS_STUDENT);
    if (student) {
      setAttendeeId(student.chimeAttendeeId)
    }

  }, [roster])

  return attendeeId && attendeeIdToTileId[attendeeId] ?
    <div className={styles.remoteVideo}>
      <RemoteVideo tileId={attendeeIdToTileId[attendeeId]} />
      {
        !isFull && <LiveStreamStatusMeter attendeeId={attendeeId} userName={userName}/>
      }
    </div>
    : null
});


export default LiveStreamRemoteVideo;