export const Roles = {
    ADMIN: 'ADMIN',
    TEACHER: 'TEACHER',
    PARENT: 'PARENT',
    STUDENT: 'STUDENT',
    EDITOR: 'EDITOR',
    GUEST: 'GUEST',
    TUTOR: 'TUTOR',
    TUTOR_USER: 'TUTOR_USER',
    ONECLASS_TEACHER: 'ONECLASS_TEACHER',
    ONECLASS_STUDENT: 'ONECLASS_STUDENT',
    LIVESTREAMER: 'LIVESTREAMER'
};
