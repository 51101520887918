import React, { useEffect } from 'react';
import Reader from 'components/Reader/index';
import { useStore, StoreTypes } from 'context';
import { useLocation, useParams } from 'react-router-dom';
import { useMeetingManager, useMeetingStatus, useContentShareState, ContentShare } from 'amazon-chime-sdk-component-library-react';
import LiveStreamMasterPanel from 'components/LiveStreamMasterPanel';
import LiveStreamViewerPanel from 'components/SideToolContents/LiveStreamViewerPanel'
import LiveStreamAdminPanel from 'components/SideToolContents/LiveStreamAdminPanel'
import GuestPannel from 'components/GuestPannel';
import { useAnnotationChecker } from 'customHooks/annotationChecker';
import { Roles } from 'constants/role';
import Sidebar from 'components/Sidebar';
import styles from './index.module.scss';
import classnames from 'classnames';
import { MeetingStatus } from 'constants/meetingStatus';
import Loading from 'components/Loading';
import { COURSE_TYPE } from 'constants/courseTypes';
import LiveStreamSettingPopOver from 'components/LiveStreamSettingPopOver';



const readerClass = (role) => {
  switch (role) {
    case Roles.ONECLASS_TEACHER:
    case Roles.ONECLASS_STUDENT:
      return styles.studentMode;
    default:
      return styles.otherMode;
  }
}

const LiveStreamSelect = React.memo(({ roomName, userName, userId, chimeRole, roleType, courseType }) => {
  if (!roomName || !userName || !userId || !chimeRole) return null;
  let role = "teacher"
  switch (chimeRole) {
    case Roles.TUTOR:
    case Roles.ONECLASS_TEACHER:
      role = "teacher"
      break;
    case Roles.ADMIN:
    case Roles.TUTOR_USER:
    case Roles.ONECLASS_STUDENT:
      role = "student"
      break;
    default:
      break;
  }

  // const roomInfo = {
  //   roomName: roomName,
  //   userName: userName,
  //   role: role,
  //   userId: `${userId}$${chimeRole}`
  // }

  const meetingManager = useMeetingManager();
  const meetingStatus = useMeetingStatus();


  useEffect(()=> {
    const joinMeeting = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_CHIME_API_URL}/join?title=` + encodeURIComponent(roomName) +
        '&name='  + encodeURIComponent(userName) +
        '&region='  + encodeURIComponent('ap-southeast-1') +
        '&role='  + encodeURIComponent(role) +
        '&userId='  + encodeURIComponent(`${userId}$${chimeRole}`),
        {
          method: 'POST'
        },
      );

      const { data } = await response.json();
      const joinData = {
        meetingInfo: data.meeting,
        attendeeInfo: data.attendee
      };
      await meetingManager.join(joinData);
      await meetingManager.start();
    };

    joinMeeting();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  if(courseType === COURSE_TYPE.NORMAL){
    return null;
  }

  if (meetingStatus === MeetingStatus.Loading) {
    return <Sidebar vertical isShow={true} isFloat={false}>
      <Loading />
    </Sidebar>
  }

  if(meetingStatus === MeetingStatus.Succeeded){
    return <Sidebar className={styles.liveStreamSideBar} vertical isShow={true} isFloat={false}>
        <LiveStreamSettingPopOver />
        {
          (chimeRole === Roles.ONECLASS_TEACHER || chimeRole === Roles.TUTOR) && (
            <LiveStreamMasterPanel />
          )
        }
        {
          (chimeRole === Roles.ONECLASS_STUDENT || chimeRole === Roles.TUTOR_USER) && (
            <LiveStreamViewerPanel />
          )
        }
        {
          (chimeRole === Roles.ADMIN) && (
            <LiveStreamAdminPanel />
          )
        }
    </Sidebar>
  }
})

const ReaderWithContentShare = React.memo(({ role, ...props }) => {
  const { sharingAttendeeId } = useContentShareState();
  return <div className={styles.contentWrapper}>
    {(role === Roles.TUTOR_USER || role === Roles.ADMIN || role === Roles.ONECLASS_STUDENT) && sharingAttendeeId && (
      <div className={styles.contentShare}>
        <ContentShare />
      </div>
    ) }
     <Reader {...props}/>
  </div>
})

const GuestVideoPannel  = (props) => {
  return <Sidebar className={styles.liveStreamSideBar} vertical isShow={true} isFloat={false}>
        <GuestPannel />
    </Sidebar>
}

const ReaderActivity = (props) => {
  const { roomId } = props
  useAnnotationChecker(props);
  const [{ role, name, userId }] = useStore(StoreTypes.user);
  const [{ chimeRole, courseType }] = useStore(StoreTypes.course);
  const [{ isLiveStreamPanelShow }] = useStore(StoreTypes.reader);
  console.log("role",role,chimeRole)
  return role && <div className={classnames(styles.LiveStreamContent, readerClass(role))}>
    <ReaderWithContentShare {...props} role={chimeRole}/>
    {
      isLiveStreamPanelShow && chimeRole &&(
        <LiveStreamSelect
          isTeacher={chimeRole === Roles.ONECLASS_TEACHER}
          roomName={roomId}
          userName={name}
          userId={userId}
          chimeRole={chimeRole}
          courseType={courseType}
        />
      )
    }
    {
      role===Roles.GUEST&&process.env.REACT_APP_ROLE===Roles.LIVESTREAMER&&<GuestVideoPannel />
    }
  </div>
}

const ChimeWrapper = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  let role = query.get('role');
  let activityId = query.get('activityId');
  let teacherId = query.get('teacherId');
  let interactiveObjectId = query.get('interactiveObjectId');
  let roomId = query.get('roomId');
  let studentId = query.get('studentId');
  let timeSpanId = query.get('timeSpanId');
  let code = query.get('code');
  let otp = query.get('otp');
  const { bookId } = useParams();


  return (
        <ReaderActivity
          bookId={bookId}
          paramsRole={role}
          studentId={studentId}
          paramsTeacherId={teacherId}
          paramsInteractiveObjectId={interactiveObjectId}
          timeSpanId={timeSpanId}
          roomId={roomId}
          code={code}
          otp={otp}
          paramsActivityId={activityId}
        />
  )
}


export default ChimeWrapper


