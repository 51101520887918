import { useCallback } from 'react';
import { ReaderToolType } from 'constants/ReaderTools';
import { BaseReaderStrategy } from './BaseReaderStrategy';
import { useStore, StoreTypes } from 'context';

const onClassTools = [
    ReaderToolType.Drag,
    ReaderToolType.Select,
    ReaderToolType.Painter,
    ReaderToolType.Painting,
    ReaderToolType.Shape,
    ReaderToolType.Line,
    ReaderToolType.Highlighter,
    ReaderToolType.DeleteAll,
    ReaderToolType.Delete,
    ReaderToolType.Text,
    ReaderToolType.Img,
];

const offClassTools = [
    ReaderToolType.Drag,
    ReaderToolType.Select,
    ReaderToolType.Painter,
    ReaderToolType.Painting,
    ReaderToolType.Shape,
    ReaderToolType.Line,
    ReaderToolType.Highlighter,
    ReaderToolType.DeleteAll,
    ReaderToolType.Delete,
    ReaderToolType.ArrowLeft,
    ReaderToolType.PageSearcher,
    ReaderToolType.ArrowRight,
    ReaderToolType.FourZoomIn,
    ReaderToolType.AllScreen,
    ReaderToolType.ReaderOption,
    ReaderToolType.Text,
    ReaderToolType.Img,
    ReaderToolType.ResetLastAnnatation,
];

export const useOneClassStudentStrategy = ({ isLiveStreamPanelShow = false }) => {
    const [{ finishedBefore }] = useStore(StoreTypes.course);
    const getReaderToolComponents = useCallback((readerToolComponents) => {
        return BaseReaderStrategy.getAcceptedReaderToolComponents(readerToolComponents, finishedBefore > 0 ? offClassTools : onClassTools);
    }, [finishedBefore]);

    const isReaderSwipeable = useCallback(() => {
        return false;
    }, []);

    const isReaderSwitchPageAvailable = useCallback(() => {
        return !isLiveStreamPanelShow;
    }, [isLiveStreamPanelShow]);

    const syncAnnotation = useCallback((id) => {
        // Do nothing
    }, []);

    const isInteractiveObjectTriggerable = useCallback(({ contentType }) => {
        return false;
    }, []);

    return {
        name: 'useOneClassStudentStrategy',
        getReaderToolComponents,
        isReaderSwipeable,
        isReaderSwitchPageAvailable,
        syncAnnotation,
        isInteractiveObjectTriggerable
    };
};
