import React, { useCallback, useEffect } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import { SideToolContent } from 'constants/sideToolContents';
import { PainterMode } from 'constants/painterModes';
import {
  ReaderEvent,
  ReaderToolsEvent,
  PainterEvent,
  ActivityEvent,
  InteractiveObjectEvent,
  CanvasEvent,
  EmojiEvent,
  ToolsEvent
} from 'events/EventTypes';
import {
  useUpdateFullWidthInfo,
  useUpdateReaderScaleInfo,
  useUpdateAreaZoomInfo,
  useUpdateAreaZoomForPageButton,
  useSetPageIndex,
} from 'customHooks/reader';
import {
  useConvertJSONToSVG,
  useSaveCanvasJSON,
  useFlushAnnotations,
  useExportInteractiveObjects
} from 'customHooks/canvas';
import { ReaderToolType, SideBarType, ReaderZoomType, SVGCanvasSwitchType } from 'constants/ReaderTools';
import { Roles } from 'constants/role';
import { useEvent } from 'events/EventBus';
import { EventBusType, EventBus } from 'events/EventBus';
import { useInteractiveObjectContentCommandFactory } from 'customHooks/InteractiveObjectContentCommands/commandFactory';
import { useCanvasSVGObjectContentCommandFactory } from 'customHooks/CanvasSVGObjectContentCommands/commandFactory';
import { AnnotationType } from 'constants/annotationTypes';
import {
  useReadAnnotations,
  useUpdateAnnotation,
  useCreateAnnotation,
  useCreateReaderToolSettings,
  useReadReaderToolSettings,
  useMarkAnnotationDeleted
} from 'customHooks/db';
import { useReaderStrategyDecider } from 'customHooks/Strategies/ReaderStrategies';
import { useSyncClassPreparationDispatcher } from 'customHooks/syncClassPreparation';
import uuid, { short_uuid } from 'util/uuid';
import { isExist } from 'util/helper'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { useCreateCourseInteractiveItem, useRemoteAllCanvasObjects } from 'customHooks/canvas';
import { useCourse } from 'customHooks/course'
import { InteractiveObjectContentType } from 'constants/interactiveObjectContentTypes';
import { debounce } from 'util/debounce';
import AlertModal from 'components/common/AlertModal';

export const useReaderEventHandler = () => {
  const reducers = useStore();
  const [{ canvasSVGObjectId, pageIndex, isDoublePageMode, fullScreenState }, readerDispatch] = reducers[
    StoreTypes.reader
  ];
  const [{ canvasElement, svgElement }, canvasDispatch] = reducers[StoreTypes.canvas];
  const [{ role, userId }] = reducers[StoreTypes.user];
  const [{ books, bookId, catalog, interactiveObjectJSON, style: { width, height } }] = reducers[StoreTypes.books];
  const [{ annotationId, marks }, annotationDispatch] = reducers[
    StoreTypes.annotation
  ];
  const [{ sideToolContent }, sideToolDispatch] = reducers[StoreTypes.sideTool];
  const [{ rewardInfo, firestore }, courseDispatch] = reducers[StoreTypes.course];
  const [, globalDispatch] = reducers[StoreTypes.global];
  const router = reducers[StoreTypes.router];
  const convertJSONToSVG = useConvertJSONToSVG();
  const { saveCanvasJSON } = useSaveCanvasJSON();
  const flushAnnotations = useFlushAnnotations();
  const CommandFactory = useInteractiveObjectContentCommandFactory();
  const CanvasSVGCommandFactory = useCanvasSVGObjectContentCommandFactory();
  const exportInteractiveObjects = useExportInteractiveObjects();
  const book = books.find(book => book.bookId === bookId);
  const { readAnnotationById } = useReadAnnotations();
  const createReaderToolSettings = useCreateReaderToolSettings();
  const readReaderToolSettings = useReadReaderToolSettings();
  const updateAnnotation = useUpdateAnnotation();
  const createAnnotation = useCreateAnnotation();
  const markAnnotationDeleted = useMarkAnnotationDeleted();
  const updateFullWidthInfo = useUpdateFullWidthInfo();
  const updateReaderScaleInfo = useUpdateReaderScaleInfo();
  const { updateAreaZoomInfo, updateAreaZoomEventInfo } = useUpdateAreaZoomInfo();
  const updateAreaZoomForPageButton = useUpdateAreaZoomForPageButton();
  const decider = useReaderStrategyDecider();
  const strategy = decider.getReaderStrategy();
  const { syncClassPreparation } = useSyncClassPreparationDispatcher();
  const { setPageIndex } = useSetPageIndex();
  const createCourseInteractiveItem = useCreateCourseInteractiveItem();
  const remoteAllCanvasObjects = useRemoteAllCanvasObjects();
  const [, { broadcastEvent, setSyncCanvasTargetId ,resetCourseAnnotation}] = useCourse();

  const setPageIndexHandler =
  debounce(useCallback(async ({ pageIndex, convertToSVG }) => {
      setPageIndex({ pageIndex, convertToSVG });
      broadcastEvent({ eventType: ReaderEvent.SetPageIndexEvent, payload: { pageIndex } })
    },[broadcastEvent, setPageIndex]),100)

  const previousPageEventHandler = debounce(useCallback(
    async ({ convertToSVG }) => {
      setPageIndexHandler({
        pageIndex: Math.max(pageIndex - 1, 0),
        convertToSVG
      });
    },
    [pageIndex, setPageIndexHandler]
  ), 100);

  const nextPageEventHandler = debounce(useCallback(
    async ({ pageInfos, convertToSVG }) => {
      const max = isDoublePageMode
        ? Math.floor((pageInfos.length - 1) / 2)
        : pageInfos.length - 1;
      setPageIndexHandler({
        pageIndex: Math.min(pageIndex + 1, max),
        convertToSVG
      });
    },
    [isDoublePageMode, pageIndex, setPageIndexHandler]
  ), 100);

  const togglePageModeHandler = useCallback(
    async ({ isDoublePageMode: newPageMode, targetPageIndex }) => {
      const { pageInfos } = book;
      let newPageIndex = targetPageIndex;
      if (typeof newPageIndex === 'undefined') {
        if (newPageMode === isDoublePageMode) {
          newPageIndex = pageIndex;
        } else {
          newPageIndex = newPageMode
            ? Math.max(Math.floor(pageIndex / 2), 0)
            : Math.min(pageIndex * 2, pageInfos.length);
        }
      }
      readerDispatch({
        type: types.SET_BOOK_PAGE_INDEX,
        pageIndex: newPageIndex
      });
      if (newPageMode !== isDoublePageMode) {
        readerDispatch({
          type: types.SWITCH_BOOK_PAGES_SHOW,
          isDoublePageMode: newPageMode
        });

        await updateAnnotation(annotationId, {
          pageIndex: newPageIndex,
          isDoublePageMode: newPageMode
        });

        await flushAnnotations({
          currentPageIndex: newPageIndex,
          pageMode: newPageMode
        });

        canvasDispatch({ type: types.CANVAS_INACTIVATE });

        strategy && strategy.syncAnnotation(annotationId);
      }
    },
    [
      isDoublePageMode,
      book,
      pageIndex,
      readerDispatch,
      updateAnnotation,
      annotationId,
      flushAnnotations,
      canvasDispatch,
      strategy
    ]
  );

  const setReaderToolHeightHandler = useCallback(
    ({ readerToolHeight }) => {
      readerDispatch({
        type: types.SET_READER_TOOL_HEIGHT,
        readerToolHeight
      });
    },
    [readerDispatch]
  );

  const setReaderToolsHandler = useCallback(({ readerTools }) => {
    readerDispatch({
      type: types.SET_READER_TOOL,
      readerTools
    });
  }, [readerDispatch])

  const reimportSVG = useCallback(async ({ doInit } = {}) => {
    const canvasSVG = await convertJSONToSVG({
      keepCanvas: true,
      toSVG: true
    });
    canvasDispatch({ type: types.CANVAS_IMPORT_SVG, canvasSVG });
    canvasDispatch({ type: types.CANVAS_INACTIVATE });
  }, [canvasDispatch, convertJSONToSVG]);

  const changeStampStatusHandler = useCallback(
    ({ painterMode, stampType }) => {
      canvasDispatch({
        type: types.CANVAS_CHANGE_STAMP_STATUS,
        painterMode,
        stampType
      });
    },
    [canvasDispatch]
  );

  const toggleSideBarHandler = useCallback(
    ({ sideBarType }) => {
      readerDispatch({
        type:
          sideBarType === SideBarType.LEFT
            ? types.TOGGLE_LEFT_BAR
            : types.TOGGLE_RIGHT_BAR
      });
    },
    [readerDispatch]
  );


  const toggleFullScreenHandler = useCallback(() => {
    readerDispatch({
      type: types.SET_FULLSCREEN_STATE
    });
    const docElm = document.documentElement;
    if (fullScreenState) {
      if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }
      else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
      else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      //FireFox 
      if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      }
      //Chrome
      else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      }
      //IE11
      else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    }
  }, [fullScreenState, readerDispatch]);

  const togglePageSearcherHandler = useCallback(() => {
    readerDispatch({
      type: types.TOGGLE_PAGE_SEARCHER
    });
  }, [readerDispatch]);

  const clickReaderOptionPanelHandler = useCallback(async () => {
    sideToolDispatch({
      type: types.SET_SIDE_TOOL_CONTENT,
      sideToolContent:
        sideToolContent !== SideToolContent.ReaderOptionPanel
          ? SideToolContent.ReaderOptionPanel
          : SideToolContent.None
    });
  }, [sideToolContent, sideToolDispatch]);

  const clickStampPanelHandler = useCallback(async () => {
    canvasDispatch({ type: types.CANVAS_INACTIVATE });
    await reimportSVG();
    sideToolDispatch({
      type: types.SET_SIDE_TOOL_CONTENT,
      sideToolContent:
        sideToolContent !== SideToolContent.StampPanel
          ? SideToolContent.StampPanel
          : SideToolContent.None
    });
  }, [canvasDispatch, reimportSVG, sideToolContent, sideToolDispatch]);

  const setObjectPropertyHandler = useCallback(
    ({ objectProperty }) => {
      sideToolDispatch({
        type: types.SET_CANVAS_OBJECT_PROPERTY,
        objectProperty
      });
    },
    [sideToolDispatch]
  );

  const toggleBookmarkHandler = useCallback(() => {
    readerDispatch({
      type: types.TOGGLE_BOOKMARK
    });
  }, [readerDispatch]);

  const LoadReaderToolSettingsHandler = useCallback(async ({ role }) => {
    const response = await readReaderToolSettings(bookId);
    if (response) {
      response.painterTool &&
        canvasDispatch({
          type: types.SET_CANVAS_PAINTER_TOOL,
          painterTool: response.painterTool
        });
      response.fullWidthMode !== undefined &&
        readerDispatch({
          type: types.SET_FULL_WIDTH_INFO,
          fullWidthInfo: { mode: role === Roles.EDITOR ? false : response.fullWidthMode }
        });
    }
  }, [bookId, canvasDispatch, readReaderToolSettings, readerDispatch]);

  const SVGCanvasSwitchEventHandler = useCallback(({ switchType = SVGCanvasSwitchType.SVG }) => {
    
  }, [])

  const clickDragHandler = useCallback(async () => {
    await reimportSVG({ doInit: true });
    readerDispatch({ type: types.SET_MARK_MODE, isMarkModeShow: false });
    readerDispatch({ type: types.SET_MARK_TOOLS_SHOW, isMarkToolsShow: false });
  }, [reimportSVG, readerDispatch]);

  const changePainterModeHandler = useCallback(
    ({ painterMode, painterToolType }) => {

      readerDispatch({ type: types.SET_MARK_MODE, isMarkModeShow: false });
      canvasDispatch({
        type: types.CANVAS_CHANGE_PAINTER_MODE,
        painterMode,
        painterToolType
      });
    },
    [canvasDispatch, readerDispatch]
  );

  const changeBrushColorHandler = useCallback(
    ({ color }) => {
      canvasDispatch({
        type: types.CANVAS_CHANGE_COLOR,
        changeColorRgb: color,
        changeColorHex: color
      });
    },
    [canvasDispatch]
  );

  const changeBrushWidthHandler = useCallback(
    ({ lineWidth }) => {
      canvasDispatch({
        type: types.CANVAS_DRAWING_BRUSH_LINE_WIDTH,
        changeLineWidth: lineWidth
      });
    },
    [canvasDispatch]
  );

  const changeBrushTypeHandler = useCallback(
    ({ brushType }) => {
      canvasDispatch({
        type: types.CHANGE_DRAWING_BRUSH,
        changeDrawingBrush: brushType
      });
    },
    [canvasDispatch]
  );

  const changeShapeFillTypeHandler = useCallback(
    ({ fillType }) => {
      canvasDispatch({ type: types.CANVAS_CHANGE_SHAPE_FILL_TYPE, fillType });
    },
    [canvasDispatch]
  );

  const changelineTypeHandler = useCallback(({ lineType }) => {
    canvasDispatch({ type: types.CANVAS_CHANGE_LINE_TYPE, lineType });
  }, [canvasDispatch])


  const changePainterTypeHandler = useCallback(
    ({ painterType }) => {
      canvasDispatch({ type: types.CANVAS_CHANGE_PAINTER_TYPE, painterType });
    },
    [canvasDispatch]
  );

  const changeReaderToolStyleHandler = useCallback(
    ({ color }) => {
      readerDispatch({ type: types.CHANGE_READER_TOOL_STYLE, color });
    },
    [readerDispatch]
  );

  const changeReaderToolDirectionHandler = useCallback(
    direction => {
      readerDispatch({ type: types.CHANGE_READER_TOOL_DIRECTION, direction });
    },
    [readerDispatch]
  );

  const changeTextColorHandler = useCallback(
    ({ color }) => {
      canvasDispatch({
        type: types.CANVAS_CHANGE_TEXT_COLOR,
        changeColorRgb: color
      });
    },
    [canvasDispatch]
  );

  const changeTextFontSizeHandler = useCallback(
    ({ fontSize }) => {
      canvasDispatch({
        type: types.CANVAS_CHANGE_TEXT_FONTSIZE,
        changeFontSize: fontSize
      });
    }, [canvasDispatch])

  const changeTextFontStyleHandler = useCallback(
    ({ fontStyle }) => {
      canvasDispatch({
        type: types.CANVAS_CHANGE_TEXT_FONTSTYLE,
        changeFontStyle: fontStyle
      });
    },
    [canvasDispatch]
  );

  const changeTextFontWeightHandler = useCallback(
    ({ fontWeight }) => {
      canvasDispatch({
        type: types.CANVAS_CHANGE_TEXT_FONTWEIGHT,
        changeFontWeight: fontWeight
      });
    },
    [canvasDispatch]
  );

  const changeTextUnderlineHandler = useCallback(
    ({ underline }) => {
      canvasDispatch({
        type: types.CANVAS_CHANGE_TEXT_UNDERLINE,
        changeUnderline: underline
      });
    },
    [canvasDispatch]
  );

  const changeTextBgColorHandler = useCallback(
    ({ backgroundColor }) => {
      canvasDispatch({
        type: types.CANVAS_CHANGE_TEXT_BGCOLOR,
        changeBgColor: backgroundColor
      });
    },
    [canvasDispatch]
  );

  const RefreshCanvasHandler = useCallback(
    async ({ result, size }) => {
      canvasDispatch({
        type: types.CANVAS_RESTORE_FROM_DB,
        annotations: result.annotations
      });
      await flushAnnotations({
        annotations: result.annotations.reduce((acc, v) => {
          acc[v.pageIndex] = v.annotation;
          return acc;
        }, {}),
        currentPageIndex: result.pageIndex,
        size
      });
    },
    [canvasDispatch, flushAnnotations]
  );

  const toolsControlEventHandler = useCallback(({ userId }) => {
    firestore && setSyncCanvasTargetId({ syncCanvasTargetId: userId.split("$")[0] })
  }, [firestore, setSyncCanvasTargetId])

  const addUpEmojiEventHandler = useCallback(({ emoji }) => {
    broadcastEvent({ eventType: EmojiEvent.AddUpEmojiEvent, payload: { emoji } })
  }, [broadcastEvent])

  const sendExpressionEventHandler = useCallback(({ expressionType, rewardInfo, remote = false }) => {
    broadcastEvent({ eventType: ReaderToolsEvent.SendExpressionEvent, payload: { expressionType, rewardInfo, remote: true } })
    if (!expressionType || remote) return;
    courseDispatch({ type: types.INCREASE_REWARD, expressionType })
  }, [broadcastEvent, courseDispatch])

  const setSaveCanvasJsonTimeHandler = useCallback(({ saveCanvasTime }) => {
    canvasDispatch({ type: types.SAVE_CANVAS_JSON_TIME, saveCanvasTime });
  }, [canvasDispatch])

  const setRewardInfoEventHandler = useCallback(({ rewardInfo }) => {
    courseDispatch({ type: types.SET_REWARD_INFO, rewardInfo })
  }, [courseDispatch])

  const playExpressionEventHandler = useCallback(({ expressionType }) => {
    broadcastEvent({ eventType: ReaderToolsEvent.PlayExpression, payload: { expressionType } })
    readerDispatch({
      type: types.SET_EXPRESSION_TYPE,
      expressionType
    });
  }, [broadcastEvent, readerDispatch])

  const closeModalEventHandler = useCallback(() => {
    
    globalDispatch({
      type: types.CLOSE_MODAL
    })
    globalDispatch({
      type: types.CLOSE_MUSIC_MODAL
    });
  }, [globalDispatch])

  const clickInteractiveObjectHandler = useCallback(
    async ({
      id,
      pageIndex,
      isDoublePageMode,
      interactiveObjectState,
      setInteractiveObjectState,
      target,
      remote = false
    }) => {
      const obj = interactiveObjectJSON[pageIndex];
      if (obj && obj[id]) {
        if (strategy.isInteractiveObjectTriggerable(obj[id]) || remote) {
          switch (obj[id].contentType) {
            case InteractiveObjectContentType.Submenu:
            case InteractiveObjectContentType.GoPage:

              break;
            case InteractiveObjectContentType.ToggleDisplay:
              obj[id].src=""//因為ToggleDisplay會連帶圖檔的base64進去，同步時會讓socket掛掉
              break;

            default:
              // firestore && setEventStatusToFirebase({ interactiveObjectEvent: { json: { ...obj[id], time: new Date().getTime() } } })
              break;
          }
          broadcastEvent({
            eventType: InteractiveObjectEvent.SetInteractiveObjectStateEvent,
            payload: {
              id,
              json: obj[id],
              pageIndex,
              isDoublePageMode,
              interactiveObjectState,
              setInteractiveObjectState,
              target,
              remote: true
            }
          })
          const command = CommandFactory.createCommand(obj[id]);
          command &&
            command.execute({
              json: obj[id],
              pageIndex,
              isDoublePageMode,
              interactiveObjectState,
              setInteractiveObjectState,
              interactiveObjectJSON,
              target
            });
        } else {
          role === Roles.TUTOR_USER ?
            alert("要認真上課，不要亂點喔~") :
            alert("此功能於未來推出，敬請期待唷 ❤")
        }
      }
    },
    [broadcastEvent, interactiveObjectJSON, strategy, CommandFactory, role]
  );

  const exportInteractiveObjectEventHandler = useCallback(async () => {
    readerDispatch({
      type: types.SET_INDICATOR_INFO,
      indicatorInfo: { isActive: true }
    });
    const pageIndices = [];
    if (isDoublePageMode) {
      pageIndices.push.apply(pageIndices, [pageIndex * 2, pageIndex * 2 + 1]);
    } else {
      pageIndices.push.apply(pageIndices, [pageIndex]);
    }
    await exportInteractiveObjects(pageIndices);
    readerDispatch({
      type: types.SET_INDICATOR_INFO,
      indicatorInfo: { isActive: false }
    });
  }, [exportInteractiveObjects, isDoublePageMode, pageIndex, readerDispatch]);

  const createAndEnterAnnotationEventHandler = useCallback(
    async ({
      annotationId,
      bookId,
      annotationType,
      annotations,
      name,
      pageIndex = 0,
      createNewAnnotation = false,
      callback
    }) => {

      if (!createNewAnnotation && !annotationId) {
        createNewAnnotation = true;
        annotationId = uuid();
      }

      // if (annotationType === AnnotationType.GUEST) {
      //     const annotations = await readAnnotations({ bookId, annotationType });
      //     if (annotations.length > 0) {
      //         annotationId = annotations[0].id;
      //         createNewAnnotation = false;
      //     }
      // }

      if (createNewAnnotation) {
        await createAnnotation({
          id: annotationId,
          bookId,
          name,
          type: annotationType,
          pageIndex,
          annotations: annotations || [],
          isUpdate: false
        });
        syncClassPreparation(annotationId);
      }

      if (annotationType === AnnotationType.ACTIVITY) {
        return EventBus.emit({
          event: createNewAnnotation
            ? ActivityEvent.CreatActivityEvent
            : ActivityEvent.EnterActivityEvent,
          payload: {
            activityId: annotationId,
            annotationType,
            bookId,
            annotations,
            name,
            callback
          }
        });
      }
      // enter
      annotationDispatch({
        type: types.UPDATE_ANNOTATION_INFO,
        annotationId,
        annotationType,
        name
      });
      callback && callback();
    },
    [annotationDispatch, createAnnotation, syncClassPreparation]
  );

  const clickBookmarkEventHandler = useCallback(
    async ({ defaultPageIndex, bookId: targetBookId }) => {
      // const pageIndex = isDoublePageMode ? Math.floor(page / 2) : page;

      if (bookId === targetBookId) {

        let index = defaultPageIndex;
        if (catalog.length > 0) {
          const { bookmarkRecord } = await readAnnotationById({ id: annotationId });
          const bookmarkIndex = catalog.getBookmarkIndex(defaultPageIndex);

          if (bookmarkRecord && isExist(bookmarkRecord[bookmarkIndex])) {
            const record = bookmarkRecord[bookmarkIndex]; // this value will from single page mode
            index = record;
          } else {
            const recordObj = Object.assign(bookmarkRecord || {}, { [bookmarkIndex]: index })
            await updateAnnotation(annotationId, {
              bookmarkRecord: recordObj
            });
          }
        }

        setPageIndex({
          convertToSVG: true,
          pageIndex: isDoublePageMode ? Math.floor(index / 2) : index
        });
      } else {
        router.history.replace(`/loading?bookId=${targetBookId}`);
      }
    },
    [annotationId, bookId, catalog, isDoublePageMode, readAnnotationById, router.history, setPageIndex, updateAnnotation]
  );

  const goBackCatalogEventHandler = useCallback(() => {
    let index = catalog.getCatalogIndex(isDoublePageMode, pageIndex);
    setPageIndex({
      convertToSVG: true,
      pageIndex: isDoublePageMode ? Math.floor(index / 2) : index
    });
  }, [catalog, isDoublePageMode, pageIndex, setPageIndex]);

  const clickOpenActivityEventHandler = useCallback(
    async ({ annotationId }) => {
      readerDispatch({
        type: types.SET_INDICATOR_INFO,
        indicatorInfo: { isActive: true }
      });
      let result = await readAnnotationById({ id: annotationId });
      createAndEnterAnnotationEventHandler({
        ...result,
        annotationId: null,
        annotationType: AnnotationType.ACTIVITY,
        callback: err =>
          readerDispatch({
            type: types.SET_INDICATOR_INFO,
            indicatorInfo: { isActive: false }
          })
      });
    },
    [createAndEnterAnnotationEventHandler, readAnnotationById, readerDispatch]
  );

  const OnMarkModeEventHandler = useCallback(async () => {
    readerDispatch({
      type: types.SET_MARK_MODE,
      isMarkModeShow: true
    });
    readerDispatch({
      type: types.SET_MARK_TOOLS_SHOW,
      isMarkToolsShow: false
    });
    await reimportSVG();
  }, [readerDispatch, reimportSVG]);

  const SetMarkToolsEnableEventHandler = useCallback(
    ({ enable }) => {
      readerDispatch({
        type: types.SET_MARK_TOOLS_SHOW,
        isMarkToolsShow: enable
      });
    },
    [readerDispatch]
  );

  const SelectMarkEventHandler = useCallback(
    ({ markObject }) => {
      SetMarkToolsEnableEventHandler({ enable: true });
      annotationDispatch({ type: types.SET_MARK_OBJECT, markObject });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [SetMarkToolsEnableEventHandler, annotationDispatch]
  );

  const SaveMarkEventHandler = useCallback(
    async ({ markObject, markId, pageIndex }) => {
      annotationDispatch({
        type: types.ADD_MARK_OBJECTS,
        markObject: { text: markObject.text, id: markId, pageIndex }
      });
      await updateAnnotation(annotationId, {
        marks: [...marks, { text: markObject.text, id: markId, pageIndex }],
        isDirty: true,
        updatedAt: Date.now()
      });
      strategy && strategy.syncAnnotation(annotationId);
    },
    [annotationDispatch, annotationId, marks, strategy, updateAnnotation]
  );

  const SaveReaderToolSettingsEventHandler = useCallback(
    async payload => {
      await createReaderToolSettings(bookId, payload);
    },
    [bookId, createReaderToolSettings]
  );

  const clickEraseAllButtonEventHandler = useCallback((callback) => () => {
      remoteAllCanvasObjects({ EventBusType });
      canvasDispatch({ type: types.CANVAS_INACTIVATE })
      canvasDispatch({ type: types.CANVAS_ERASE_ALL });
      setTimeout(() => {
        saveCanvasJSON();
        EventBus.emit({
          event: ReaderToolsEvent.ClickDragEvent
        });
        readerDispatch({ type: types.SET_READER_TOOL_TYPE, readerToolType: ReaderToolType.Drag });
        canvasDispatch({ type: types.CANVAS_RESET_SVG });
        callback();
      }, 0);
  },[canvasDispatch, readerDispatch, remoteAllCanvasObjects, saveCanvasJSON])

  const clickEraseAllEventHandler = useCallback(() => {

    confirmAlert({
      title: '全部刪除',
      message: '是否刪除當前物件',
      customUI: ({ title, message, onClose }) => (
        <AlertModal 
          title={title}
          message={message}
          onConfirm={clickEraseAllButtonEventHandler(onClose)}
          onClose={onClose}
          confirmLabel="確定刪除"
        />
      ),
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  }, [clickEraseAllButtonEventHandler]);

  const ZoomToolEventHandler = useCallback(
    ({ type }) => {
      readerDispatch({ type: types.SET_READER_ZOOM_TYPE, zoomType: type });
    },
    [readerDispatch]
  );

  const SetReaderZoomEventHandler = useCallback(
    ({ type }) => {
      if(type === ReaderZoomType.OriginZoom){
        broadcastEvent({ 
          eventType: ReaderToolsEvent.SetRemoteZoomInfoEventHandler, 
          payload: { 
            zoomInfo: {
              scaleX: 0,
              scaleY: 0,
            }
          } 
        });
      }
      broadcastEvent({ eventType: ReaderToolsEvent.SetReaderZoomEvent , payload: { type } })
      ZoomToolEventHandler({ type })
    },
    [ZoomToolEventHandler, broadcastEvent]
  );

  const areaZoomEventHandler = useCallback(
    ({ type, rect }) => {
      broadcastEvent({ eventType: ReaderEvent.AreaZoomEvent , payload: { type, rect } })
      const zoomInfo = updateAreaZoomInfo(rect);
      readerDispatch({ type: types.SET_READER_ZOOM_TYPE, zoomType: type })
      readerDispatch({ type: types.SET_AREA_ZOOM_INTERACTIVE_OBJECTS, areaZoomInteractiveObjects: null })
    },
    [broadcastEvent, updateAreaZoomInfo, readerDispatch]
  );

  const SetAreaZoomIntoEventHandler = useCallback(
    ({ type, rect, zoomInfo }) => {
      broadcastEvent({ eventType: ReaderEvent.SetAreaZoomIntoEvent , payload: { type, rect, zoomInfo } })
      updateAreaZoomEventInfo({ type, rect, zoomInfo });
      readerDispatch({ type: types.SET_READER_ZOOM_TYPE, zoomType: type });
      readerDispatch({ type: types.SET_AREA_ZOOM_INTERACTIVE_OBJECTS, areaZoomInteractiveObjects: null })
    },
    [broadcastEvent, updateAreaZoomEventInfo, readerDispatch]
  );

  const AreaZoomForPageButtonEventHandler = useCallback(
    ({ rect, areaZoomInteractiveObjects }) => {
      updateAreaZoomForPageButton(rect);
      areaZoomInteractiveObjects && readerDispatch({ type: types.SET_AREA_ZOOM_INTERACTIVE_OBJECTS, areaZoomInteractiveObjects })
    },
    [readerDispatch, updateAreaZoomForPageButton]
  );

  const setRemoteZoomInfo = useCallback(({ zoomInfo }) => {
    courseDispatch({ type: types.SET_REMOTE_ZOOM_INFO, remoteZoomInfo: zoomInfo });
    updateAreaZoomEventInfo({ zoomInfo });
      readerDispatch({ type: types.SET_AREA_ZOOM_INTERACTIVE_OBJECTS, areaZoomInteractiveObjects: null })
  },[courseDispatch, readerDispatch, updateAreaZoomEventInfo])

  const clickFullWidthEventHandler = useCallback(
    ({ fullWidthMode }) => {
      EventBus.emit({
        event: ReaderToolsEvent.SaveReaderToolSettingsEvent,
        payload: { fullWidthMode }
      });
      readerDispatch({
        type: types.SET_FULL_WIDTH_INFO,
        fullWidthInfo: { mode: fullWidthMode }
      });
    },
    [readerDispatch]
  );

  const setReaderToolTypeEventHandler = useCallback(
    ({ readerToolType }) => {
      readerDispatch({ type: types.SET_READER_TOOL_TYPE, readerToolType });
      if (readerToolType === ReaderToolType.Mark) {
        EventBus.emit({
          event: ReaderToolsEvent.SVGCanvasSwitchEvent, payload: { switchType: SVGCanvasSwitchType.SVG }
        });
        canvasDispatch({ type: types.CANVAS_INACTIVATE });
      }
    },
    [canvasDispatch, readerDispatch]
  );

  const setMathToolsEventHandler = useCallback(
    ({ isMathToolsShow }) => {
      readerDispatch({ type: types.SET_MATH_TOOLS_SHOW, isMathToolsShow });
    },
    [readerDispatch]
  );


  useEffect(() => updateFullWidthInfo(), [updateFullWidthInfo]);
  useEffect(() => updateReaderScaleInfo(), [updateReaderScaleInfo]);

  const setDrawAreaEventHandler = useCallback(
    ({ drawAreaInfo }) => {
      readerDispatch({
        type: types.SET_READER_ZOOM_TYPE,
        zoomType: ReaderZoomType.OriginZoom
      });
      readerDispatch({ type: types.TOGGLE_DRAW_AREA, drawAreaInfo });
      SetReaderZoomEventHandler({ type: ReaderZoomType.OriginZoom })
    },
    [SetReaderZoomEventHandler, readerDispatch]
  );

  const SaveAnnotationNameEventHandler = useCallback(
    async ({ annotationId, name }) => {
      const annotation = await updateAnnotation(annotationId, { name });
      if (annotation.type === AnnotationType.CLASS_PREPARATION) {
        syncClassPreparation(annotationId);
      }
    },
    [syncClassPreparation, updateAnnotation]
  );

  const DeleteAnnotationEventHandler = useCallback(
    async ({ id }) => {
      const annotation = await markAnnotationDeleted(id);
      if (annotation.type === AnnotationType.CLASS_PREPARATION) {
        syncClassPreparation(id);
      }
    },
    [markAnnotationDeleted, syncClassPreparation]
  );

  const CopyAnnotationEventHandler = useCallback(
    async ({ id, bookId, name, type, pageIndex, annotations, canvasSVGObjects, extendedContentAnnotation }) => {
      await createAnnotation({
        id: uuid(),
        bookId,
        name,
        type,
        pageIndex,
        annotations: annotations || [],
        canvasSVGObjects: canvasSVGObjects || [],
        extendedContentAnnotation: extendedContentAnnotation || [],
        isUpdate: false
      });
    },
    [createAnnotation]
  );

  const GetLocalAnnotationEventHandler = useCallback(async ({ id, callback }) => {
    const annotation = await readAnnotationById({ id });
    callback(annotation.annotations);
  }, [readAnnotationById]);

  const clickOfflineReaderToolBoxEventHandler = useCallback(() => {

  }, []);

  const clickToolBoxEventHandler = useCallback(({ isToolboxShow }) => {
    readerDispatch({ type: types.SET_TOOLBOX_SHOW, isToolboxShow });
  }, [readerDispatch]);

  const SetObjectPointerStatusEventHandler = useCallback(({ objectPointerEventStatus }) => {
    localStorage.setItem('objectPointerEventStatus', objectPointerEventStatus);
    readerDispatch({
      type: types.SET_OBJECT_POINTER_EVENT_STATUS,
      objectPointerEventStatus
    });
  }, [readerDispatch])

  const setCanvasSVGObjectIdEventHandler = useCallback(({ canvasSVGObjectId }) => {
    readerDispatch({ type: types.SET_CANVAS_SVG_OBJECT_ID, canvasSVGObjectId });
    EventBus.emit({
      event: ReaderToolsEvent.ClickDragEvent
    });
    readerDispatch({ type: types.SET_READER_TOOL_TYPE, readerToolType: ReaderToolType.Drag });
  }, [readerDispatch])

  const sendCanvasSVGObjectAnnotationEventHandler = useCallback(({ annotations }) => {
    broadcastEvent({ eventType: CanvasEvent.SendCanvasSVGObjectAnnotationEvent , payload: { annotations } })
  }, [broadcastEvent])

  const CreateCourseInteractiveItemEventHandler = useCallback(
    async ({ painterMode, painterToolType, contentType, isNew = false }) => {
      let { canvasSVGObjects } = await readAnnotationById({ id: annotationId });
      const id = isNew ? short_uuid() : canvasSVGObjectId ? canvasSVGObjectId : short_uuid()
      let canvasSVGObject = canvasSVGObjects.find((obj) => obj.id === id);
      const object = { id, contentType, pageIndex, info: { text: "", color: "#FFE692" } }

      if (!canvasSVGObjects) {
        canvasSVGObjects = []
      }

      if (!canvasSVGObject) {
        createCourseInteractiveItem({ id, contentType })
        canvasSVGObjects.push(object)
        const annotation = await updateAnnotation(annotationId, { canvasSVGObjects });
        canvasDispatch({
          type: types.CANVAS_CHANGE_PAINTER_MODE,
          painterMode,
          painterToolType
        });
      }

      EventBus.emit({
        eventBusType: EventBusType.ExtendedContent,
        event: CanvasEvent.CanvasFinishPaintingEvent,
        payload: { canvasSVGObjectId: id }
      });

      setCanvasSVGObjectIdEventHandler({ canvasSVGObjectId: id })
    }, [setCanvasSVGObjectIdEventHandler, canvasSVGObjectId, createCourseInteractiveItem, pageIndex, readAnnotationById, annotationId, updateAnnotation, canvasDispatch])

  const ExecuteCanvasSVGCommandEventHandler = useCallback(({ canvasSVGObject }) => {
    if(!canvasSVGObject) return;
    const command = CanvasSVGCommandFactory.createCommand(canvasSVGObject);
    command &&
      command.execute({
        canvasSVGObject
      });
  }, [CanvasSVGCommandFactory])

  const ResetReaderStateEventHander = useCallback(({ userId }) => {
    broadcastEvent({ eventType: ReaderEvent.ResetReaderStateEvent , payload: { userId } })
  }, [broadcastEvent])

  const ClickCanvasSVGObjectEventHandler = useCallback(async ({ annotationId, id, target }) => {
    broadcastEvent({ eventType: ReaderEvent.ClickCanvasSVGObjectEvent , payload: { annotationId, id, target } })
    if (!annotationId) { return; }
    const { canvasSVGObjects, extendedContentAnnotation } = await readAnnotationById({ id: annotationId });
    if (!canvasSVGObjects) { return; }
    const canvasSVGObject = canvasSVGObjects.find((obj) => obj.id === id)
    if (!canvasSVGObject) { return; }
    ExecuteCanvasSVGCommandEventHandler({ canvasSVGObject: { ...canvasSVGObject, extendedContentAnnotation }, target })
    setCanvasSVGObjectIdEventHandler({ canvasSVGObjectId: id })
  }, [broadcastEvent, readAnnotationById, ExecuteCanvasSVGCommandEventHandler, setCanvasSVGObjectIdEventHandler])

  const SaveCanvasSVGObjectEventHandler = useCallback(async ({ annotationId, canvasSVGObject }) => {
    const { canvasSVGObjects } = await readAnnotationById({ id: annotationId });
    const newCanvasSVGObjects = canvasSVGObjects.map((obj) => {
      if (obj.id === canvasSVGObject.id) {
        return canvasSVGObject
      } else {
        return obj
      }
    })
    const annotation = await updateAnnotation(annotationId, { canvasSVGObjects: newCanvasSVGObjects });
    strategy && strategy.syncAnnotation(annotationId);
  }, [readAnnotationById, updateAnnotation, strategy])

  const DeleteCanvasSVGObjectInfoEventHandler = useCallback(async ({ objectIds }) => {
    const { canvasSVGObjects, extendedContentAnnotation } = await readAnnotationById({ id: annotationId });
    const newCanvasSVGObjects = canvasSVGObjects && canvasSVGObjects.filter((obj) => {
      if (objectIds.find((id) => id === obj.id)) {
        return false
      }
      return obj;
    })

    const newExtendedContentAnnotation = extendedContentAnnotation && extendedContentAnnotation.filter((obj) => {
      if (objectIds.find((id) => id === obj.id)) {
        return false
      }
      return obj;
    })

    const annotation = await updateAnnotation(annotationId, {
      canvasSVGObjects: newCanvasSVGObjects,
      extendedContentAnnotation: newExtendedContentAnnotation,
    });
    strategy && strategy.syncAnnotation(annotationId);
  }, [strategy, annotationId, readAnnotationById, updateAnnotation])

  const SetProgressEventHander = useCallback(({ progress }) => {
    readerDispatch({ type: types.SET_PROGRESS, progress });
  }, [readerDispatch])


  const setFullVideoHandler = useCallback(({ isFullVideo }) => {
    courseDispatch({ type: types.SET_IS_FULL_VIDEO, isFullVideo })
  }, [courseDispatch])

  const setFullScreenTargetHandler = useCallback(({ fullScreenTarget }) => {
    courseDispatch({ type: types.SET_ADMIN_FULL_SCREEN_TARGET, fullScreenTarget })
  }, [courseDispatch])

  const sendFullVideoHandler = useCallback(({ isFullVideo }) => {
    broadcastEvent({ eventType: ReaderEvent.sendFullVideoEvent , payload: { isFullVideo } })
    setFullVideoHandler({ isFullVideo })
  }, [broadcastEvent, setFullVideoHandler])

  const ResetCourseAnnotationEventHandler  = useCallback(() => {
    firestore && resetCourseAnnotation()
  }, [firestore,resetCourseAnnotation])

  useEvent({ event: ReaderEvent.setFullScreenTargetEvent }, setFullScreenTargetHandler);
  useEvent({ event: ReaderEvent.setFullVideoEvent }, setFullVideoHandler);
  useEvent({ event: ReaderEvent.sendFullVideoEvent }, sendFullVideoHandler);

  useEvent({ event: ReaderToolsEvent.ResetCourseAnnotationEvent }, ResetCourseAnnotationEventHandler);

  useEvent(
    [
      { event: ReaderEvent.SetPageIndexEvent },
      { event: ReaderEvent.ClickBookThumbnailEvent }
    ],
    setPageIndexHandler
  );

  useEvent(
    { event: ReaderEvent.ClickPreviousPageEvent },
    previousPageEventHandler
  );
  useEvent({ event: ReaderEvent.ClickNextPageEvent }, nextPageEventHandler);

  useEvent({ event: ReaderEvent.DrawAreaEvent }, setDrawAreaEventHandler);

  useEvent(
    { event: ReaderEvent.SaveAnnotationNameEvent },
    SaveAnnotationNameEventHandler
  );
  useEvent(
    { event: ReaderEvent.DeleteAnnotationEvent },
    DeleteAnnotationEventHandler
  );
  useEvent(
    { event: ReaderEvent.CopyAnnotationEvent },
    CopyAnnotationEventHandler
  );
  useEvent(
    { event: ReaderEvent.SetProgressEvent },
    SetProgressEventHander
  );

  useEvent(
    { event: ReaderEvent.GetLocalAnnotationEvent },
    GetLocalAnnotationEventHandler
  );

  useEvent(
    { event: ReaderToolsEvent.TogglePageModeEvent },
    togglePageModeHandler
  );
  useEvent(
    { event: ReaderToolsEvent.SetReaderToolHeight },
    setReaderToolHeightHandler
  );
  useEvent(
    { event: ReaderToolsEvent.SetReaderToolsEvent },
    setReaderToolsHandler
  );
  useEvent({ event: ReaderToolsEvent.ClickDragEvent }, clickDragHandler);
  useEvent({ event: ReaderToolsEvent.ReimportSVGEvent }, reimportSVG);
  useEvent(
    { event: ReaderToolsEvent.ChangeStampStatusEvent },
    changeStampStatusHandler
  );
  useEvent(
    { event: ReaderToolsEvent.ToggleSideBarEvent },
    toggleSideBarHandler
  );
  useEvent(
    { event: ReaderToolsEvent.ToggleFullScreen },
    toggleFullScreenHandler
  );
  useEvent(
    { event: ReaderToolsEvent.TogglePageSearcherEvent },
    togglePageSearcherHandler
  );
  useEvent(
    { event: ReaderToolsEvent.ClickReaderOptionPanelEvent },
    clickReaderOptionPanelHandler
  );
  useEvent(
    { event: ReaderToolsEvent.ClickStampPanelEvent },
    clickStampPanelHandler
  );
  useEvent(
    { event: ReaderToolsEvent.SetObjectPropertyEvent },
    setObjectPropertyHandler
  );
  useEvent(
    { event: ReaderToolsEvent.ToggleBookmarkEvent },
    toggleBookmarkHandler
  );
  useEvent(
    { event: ReaderToolsEvent.ClickOpenActivityEvent },
    clickOpenActivityEventHandler
  );
  useEvent({ event: ReaderToolsEvent.OnMarkModeEvent }, OnMarkModeEventHandler);
  useEvent({ event: ReaderToolsEvent.SelectMarkEvent }, SelectMarkEventHandler);
  useEvent(
    { event: ReaderToolsEvent.SetMarkToolsEnableEvent },
    SetMarkToolsEnableEventHandler
  );
  useEvent({ event: ReaderToolsEvent.SaveMarkEvent }, SaveMarkEventHandler);
  useEvent(
    { event: ReaderToolsEvent.SetReaderZoomEvent },
    SetReaderZoomEventHandler
  );
  useEvent(
    { event: ReaderToolsEvent.ZoomToolEvent },
    ZoomToolEventHandler
  );
  useEvent({ event: ReaderEvent.AreaZoomEvent }, areaZoomEventHandler);
  useEvent({ event: ReaderEvent.SetAreaZoomIntoEvent }, SetAreaZoomIntoEventHandler);
  useEvent({ event: ReaderEvent.ClickCanvasSVGObjectEvent }, ClickCanvasSVGObjectEventHandler);
  useEvent({ event: ReaderEvent.ResetReaderStateEvent }, ResetReaderStateEventHander);
  useEvent({ event: ReaderEvent.ExecuteCanvasSVGCommandEvent }, ExecuteCanvasSVGCommandEventHandler);
  useEvent({ event: ReaderEvent.SaveCanvasSVGObjectEvent }, SaveCanvasSVGObjectEventHandler);
  useEvent({ event: ReaderEvent.DeleteCanvasSVGObjectInfoEvent }, DeleteCanvasSVGObjectInfoEventHandler);
  useEvent(
    { event: ReaderEvent.AreaZoomForPageButtonEvent },
    AreaZoomForPageButtonEventHandler
  );

  useEvent({ event: ReaderEvent.SetCanvasSVGObjectIdEvent }, setCanvasSVGObjectIdEventHandler);

  useEvent(
    { event: ReaderToolsEvent.ClickFullWidthEvent },
    clickFullWidthEventHandler
  );
  useEvent(
    { event: ReaderToolsEvent.SaveReaderToolSettingsEvent },
    SaveReaderToolSettingsEventHandler
  );
  useEvent(
    [
      { event: ReaderToolsEvent.ClickSelectEvent },
      { event: ReaderToolsEvent.ClickPainterEvent },
      { event: ReaderToolsEvent.ClickEraserEvent },
      { event: ReaderToolsEvent.ClickInsertImageEvent },
      { event: ReaderToolsEvent.ClickInsertTextEvent }
    ],
    changePainterModeHandler
  );
  useEvent(
    { event: ReaderToolsEvent.SVGCanvasSwitchEvent },
    SVGCanvasSwitchEventHandler
  );

  useEvent(
    { event: PainterEvent.LoadReaderToolSettingsEvent },
    LoadReaderToolSettingsHandler
  );
  useEvent(
    { event: PainterEvent.ChangeBrushColorEvent },
    changeBrushColorHandler
  );
  useEvent(
    { event: PainterEvent.ChangeBrushWidthEvent },
    changeBrushWidthHandler
  );
  useEvent(
    { event: PainterEvent.ChangeBrushTypeEvent },
    changeBrushTypeHandler
  );
  useEvent(
    { event: PainterEvent.ChangeShapeFillTypeEvent },
    changeShapeFillTypeHandler
  );
  useEvent(
    { event: PainterEvent.ChangeTextColorEvent },
    changeTextColorHandler
  );
  useEvent(
    { event: PainterEvent.ChangeTextFontSizeEvent },
    changeTextFontSizeHandler
  );
  useEvent(
    { event: PainterEvent.ChangeTextFontStyleEvent },
    changeTextFontStyleHandler
  );
  useEvent(
    { event: PainterEvent.ChangeTextFontWeightEvent },
    changeTextFontWeightHandler
  );
  useEvent(
    { event: PainterEvent.ChangeTextUnderlineEvent },
    changeTextUnderlineHandler
  );
  useEvent(
    { event: PainterEvent.ChangeTextBgColorEvent },
    changeTextBgColorHandler
  );
  useEvent({ event: PainterEvent.ChangelineTypeEvent }, changelineTypeHandler);
  useEvent(
    { event: PainterEvent.ChangePainterTypeEvent },
    changePainterTypeHandler
  );
  useEvent(
    { event: ReaderToolsEvent.ChangeReaderToolStyleEvent },
    changeReaderToolStyleHandler
  );
  useEvent(
    { event: ReaderToolsEvent.ChangeReaderToolDirectionEvent },
    changeReaderToolDirectionHandler
  );
  useEvent({ event: ReaderEvent.RefreshCanvasEvent }, RefreshCanvasHandler);
  useEvent(
    { event: InteractiveObjectEvent.ClickInteractiveObjectEvent },
    clickInteractiveObjectHandler
  );
  useEvent(
    { event: EmojiEvent.AddUpEmojiEvent },
    addUpEmojiEventHandler
  );
  useEvent(
    { event: ToolsEvent.ToolsIsControl },
    toolsControlEventHandler
  );
  useEvent(
    { event: ReaderToolsEvent.SendExpressionEvent },
    sendExpressionEventHandler
  );
  useEvent(
    { event: ReaderToolsEvent.SetSaveCanvasJsonTimeEvent },
    setSaveCanvasJsonTimeHandler
  );
  useEvent(
    { event: ReaderToolsEvent.SetRewardInfoEvent },
    setRewardInfoEventHandler
  );
  useEvent(
    { event: ReaderToolsEvent.PlayExpression },
    playExpressionEventHandler
  );
  useEvent(
    { event: ReaderToolsEvent.CloseModalEvent },
    closeModalEventHandler
  );
  
  useEvent(
    { event: InteractiveObjectEvent.ExportInteractiveObjectEvent },
    exportInteractiveObjectEventHandler
  );
  useEvent(
    { event: ReaderEvent.CreateAndEnterAnnotationEvent },
    createAndEnterAnnotationEventHandler
  );
  useEvent(
    { event: ReaderEvent.ClickBookmarkEvent },
    clickBookmarkEventHandler
  );
  useEvent(
    { event: ReaderEvent.GoBackCatalogEvent },
    goBackCatalogEventHandler
  );
  useEvent(
    { event: ReaderToolsEvent.ClickEraseAllEvent },
    clickEraseAllEventHandler
  );
  useEvent(
    { event: ReaderToolsEvent.SetReaderToolTypeEvent },
    setReaderToolTypeEventHandler
  );
  useEvent(
    { event: ReaderToolsEvent.ClickOfflineReaderToolBoxEvent },
    clickOfflineReaderToolBoxEventHandler,
  );
  useEvent(
    { event: ReaderToolsEvent.ClickReaderToolBoxEvent },
    clickToolBoxEventHandler
  )
  useEvent(
    { event: ReaderToolsEvent.SetObjectPointerStatusEvent },
    SetObjectPointerStatusEventHandler
  )
  useEvent(
    { event: ReaderToolsEvent.CreateCourseInteractiveItemEvent },
    CreateCourseInteractiveItemEventHandler
  );
  useEvent(
    { event: CanvasEvent.SendCanvasSVGObjectAnnotationEvent },
    sendCanvasSVGObjectAnnotationEventHandler
  );
  useEvent({ event: ReaderToolsEvent.ClickMathToolsEvent }, setMathToolsEventHandler)

  useEvent({ event: ReaderToolsEvent.SetRemoteZoomInfoEventHandler }, setRemoteZoomInfo)
};
