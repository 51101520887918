
import React, { useState, useEffect, useContext } from 'react';
import { useStore, StoreTypes } from 'context';
import LiveStreamLocalVideo from 'components/LiveStreamLocalVideo'
import ChatRoom from 'components/ChatRoom';
import classnames from 'classnames';
import DialogModule from 'components/Dialogs/'
// import StudentRaiseButton from './StudentRaiseButton'
import StudentList from 'components/LiveStreamStudentList';
import styles from './index.module.scss';
import { useOrderStudent } from 'customHooks/orderStudent';
import { useRemoteVideoTileState, useRosterState } from 'amazon-chime-sdk-component-library-react';
import LiveStreamRemoteVideoGroup from 'components/LiveStreamRemoteVideoGroup';
import LiveStreamEmojiCounts from 'components/LiveStreamEmojiCounts';
import LiveStreamRemoteVideo from 'components/LiveStreamRemoteVideo';
import { Roles } from 'constants/role';
import { ReaderEvent } from 'events/EventTypes';
import { EventBus } from 'events/EventBus';
import Icon from 'components/Icon';
import { Context as AttendeeContext } from 'providers/AttendeeProvider';
import { useStudentList } from 'customHooks/studentList';

// const StudentListModal = ({ setStudentListOpen, stageCount }) => {
//   return (
//     <div className={styles.listDialogBottom}>
//       <div className={styles.bottomText}>目前上台人數:{stageCount}</div>
//       <div
//         className={styles.closeListDialog}
//         onClick={() => {
//           setStudentListOpen(false)
//         }}
//       >
//         關閉
//       </div>
//     </div>
//   )
// };


const MessageDialogAction = ({ setMessageOpen }) => (
  <div className={styles.closeDialog}
    onClick={() => setMessageOpen(false)}
  >
    關閉
  </div>
)



const MessageDialogContent = () => (
  <div>
    已超過舉手人數
  </div>
);


const LiveStreamMasterPanel = () => {
  const [students, setStudents] = useState(null);
  const [{ isMultiple, isFullVideo }] = useStore(StoreTypes.course);
  //const attendeeContext = useContext(AttendeeContext)
  const studentAttendees = useStudentList();
  const { tiles, size: stageCount } = useRemoteVideoTileState();
  const { attendeeIdToTileId } = useRemoteVideoTileState();


  const [studentListOpen, setStudentListOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [videoAttendees, setVideoAttendees] = useState(new Set());
  const { roster } = useRosterState();

  useEffect(() => {
    if (isMultiple) return;
    const studentsInfo = Object.values(roster).find(item => item.externalUserId.split("$")[1] === Roles.TUTOR_USER);
    setStudents(studentsInfo)
  }, [isMultiple, students, roster])

  const showMessageDialog = () => {
    setMessageOpen(true)
  }

  

  const { orderStudent, unOrderStudent } = useOrderStudent();

  //const studentAttendees = Object.values(attendeeContext.attendee || {}) || [];

  return (
    <div className={classnames(styles.discussionPanel, styles.wrapper)}>
      <div id="content" className={styles.chatContent}>
        <div className={styles.localVideo}>
          {
            <LiveStreamLocalVideo streaming={true} />
          }
          
        </div>
        {
          isMultiple? (
            <>
              <div className={styles.remoteVideoContainer}>
                <LiveStreamRemoteVideoGroup />
              </div>
              <div className={styles.controls} onClick={() => setStudentListOpen(true)}>
                <div className={styles.studentListbutton} >
                  學生名單
              </div>
              </div>
            </>
          ) : (
              <LiveStreamEmojiCounts />
            )
        }
        <div className={styles.chatRoomWrapper}>
          <ChatRoom />
        </div>
      </div>
      {/* <DialogModule
        style={{ zIndex: 10000 }}
        open={studentListOpen}
        setOpen={setStudentListOpen}
        content={
          <ListNameTextField
            studentAttendees={studentAttendees}
            stageCount={stageCount}
            showMessageDialog={showMessageDialog}
          />
        }
      />
    */}

      <DialogModule
        style={{ zIndex: 10000 }}
        open={messageOpen}
        setOpen={setMessageOpen}
        content={
          <MessageDialogContent />
        }
        action={
          <MessageDialogAction setMessageOpen={setMessageOpen} />
        }
      /> 
      {
        studentListOpen && (
          <StudentList 
            studentAttendees={studentAttendees}
            stageCount={stageCount}
            showMessageDialog={showMessageDialog}
            orderStudent={orderStudent}
            unOrderStudent={unOrderStudent}
            attendeeIdToTileId={attendeeIdToTileId}
            onClose={() => setStudentListOpen(false)}
          />
        )
      }
    </div>
  )
}

export default LiveStreamMasterPanel;
