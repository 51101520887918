import { ReactComponent as Emoji } from './readerTools/emoji.svg';
import { ReactComponent as Eraser } from './readerTools/eraser.svg';
import { ReactComponent as Hand } from './readerTools/hand.svg';
import { ReactComponent as Markerpen } from './readerTools/markerpen.svg';
import { ReactComponent as Mouse } from './readerTools/mouse.svg';
import { ReactComponent as Orignalsize } from './readerTools/orignalsize.svg';
import { ReactComponent as Pen } from './readerTools/pen.svg';
import { ReactComponent as Text } from './readerTools/text.svg';
import { ReactComponent as Trashcan } from './readerTools/trashcan.svg';
import { ReactComponent as Bookmark } from './readerTools/bookmark.svg';
import { ReactComponent as Close } from './readerTools/close.svg';
import { ReactComponent as Circle } from './readerTools/circle.svg';
import { ReactComponent as Curve } from './readerTools/curve.svg';
import { ReactComponent as IsoscelesTriangle } from './readerTools/isoscelesTriangle.svg';
import { ReactComponent as RightTriangle } from './readerTools/rightTriangle.svg';
import { ReactComponent as Line } from './readerTools/line.svg';
import { ReactComponent as LineArrow } from './readerTools/lineArrow.svg';
import { ReactComponent as Square } from './readerTools/square.svg';
import { ReactComponent as Star } from './readerTools/star.svg';
import { ReactComponent as ChevronDown } from './readerTools/chevronDown.svg';
import { ReactComponent as Bold } from './readerTools/bold.svg';
import { ReactComponent as Italic } from './readerTools/italic.svg';
import { ReactComponent as Underline } from './readerTools/underline.svg';
import { ReactComponent as Ban } from './readerTools/ban.svg';
import { ReactComponent as Photo } from './readerTools/photo.svg';
import { ReactComponent as ZoomIn } from './readerTools/zoomIn.svg';
import { ReactComponent as FourZoomIn } from './readerTools/fourZoomIn.svg';
import { ReactComponent as FourZoomInRightTop } from './readerTools/fourZoomInRightTop.svg';
import { ReactComponent as FourZoomInRightBottom } from './readerTools/fourZoomInRightBottom.svg';
import { ReactComponent as FourZoomInLeftTop } from './readerTools/fourZoomInLeftTop.svg';
import { ReactComponent as FourZoomInLeftBottom } from './readerTools/fourZoomInLeftBottom.svg';
import { ReactComponent as Home } from './readerTools/home.svg';
import { ReactComponent as SignalWeak } from './liveStream/signal-weak.svg';
import { ReactComponent as SignalStrong } from './liveStream/signal-strong.svg';
import { ReactComponent as VolumeWeak } from './liveStream/volume-weak.svg';
import { ReactComponent as VolumeMedium } from './liveStream/volume-medium.svg';
import { ReactComponent as VolumeStrong } from './liveStream/volume-strong.svg';
import { ReactComponent as PickUp } from './liveStream/pickup.svg';



export default { 
  Emoji,
  Eraser,
  Hand, 
  Markerpen,
  Mouse,
  Orignalsize,
  Pen,
  Text,
  Trashcan,
  ZoomIn,
  Bookmark,
  Close,
  Circle,
  Curve,
  IsoscelesTriangle,
  RightTriangle,
  Line,
  LineArrow,
  Square,
  Star,
  ChevronDown,
  Bold,
  Italic,
  Underline,
  Ban,
  Photo,
  FourZoomIn,
  FourZoomInRightTop,
  FourZoomInRightBottom,
  FourZoomInLeftTop,
  FourZoomInLeftBottom,
  Home,
  SignalWeak,
  SignalStrong,
  VolumeWeak,
  VolumeMedium,
  VolumeStrong,
  PickUp,
}